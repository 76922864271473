import { Button } from "antd";

import styled from "styled-components";

export const FeaturesSection = () => {
  return (
    <Container>
      <Row>
        <Column>
          <Title>Play.</Title>
          <Paragraph>
            Dive into a world of captivating short films, shows, and
            documentaries from India and beyond! Whether you prefer On Demand
            viewing or a laid-back experience with our 24x7 FAST channel, we've
            got you covered. Enjoy the flexibility to watch your favorite films
            anytime or indulge in bite-sized Reels for quick entertainment.
          </Paragraph>
        </Column>
        <Image src="/play.png" />
      </Row>
      <Row $reverse>
        <Column>
          <Title>Profiles.</Title>
          <Paragraph>
            Showcase your talent and creativity! Our Profiles section is the
            perfect space for professionals, businesses, and filmmakers to
            shine. Create a Professional Profile to highlight your skills, a
            Business Profile to promote your company, or a Film Profile to share
            your cinematic creations. Connect with audiences and industry
            experts effortlessly.
          </Paragraph>
        </Column>
        <Image src="/profiles.png" />
      </Row>
      <Row>
        <Column>
          <Title>Distribution.</Title>
          <Paragraph>
            You have done the hard work and created your film. Now leave it to
            us to take it to the right audiences, wherever they are. Not only
            can your film be on our platforms but we will also syndicate it to
            other platforms across the world to maximise viewership and
            monetization for you.
          </Paragraph>
          <Row style={{ gap: "0", alignItems: "center" }}>
            {/* <Button
              type="link"
              size="large"
              style={{ width: "fit-content", marginTop: "1rem" }}
            >
              Know more
            </Button> */}
            <Button
              onClick={() => {
                window.open(
                  process.env.REACT_APP_DISTRIBUTE_URL ??
                    "https://distribute.pocketfilms.in"
                );
              }}
              type="primary"
              size="large"
              style={{ width: "fit-content", marginTop: "1rem" }}
            >
              Submit now
            </Button>
          </Row>
        </Column>
        <Image src="distribution.png" />
      </Row>
      <Row $reverse>
        <Column>
          <Title>Festivals.</Title>
          <Paragraph>
            It is every creator's dream to be recognized by their peers from
            across the world. Pocket Films helps you to reach out to festivals
            worldwide at the click of a button, all you need to do is submit
            your film once.
          </Paragraph>
        </Column>
        <Image src="festivals.png" />
      </Row>
    </Container>
  );
};

const Container = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  padding: 0 8rem;
  gap: 4rem;
`;

const Row = styled.div<{
  $reverse?: boolean;
}>`
  width: 100%;
  display: flex;
  flex-direction: ${({ $reverse }) => ($reverse ? "row-reverse" : "row")};
  gap: 5rem;
  justify-content: space-between;

  @media only screen and (max-width: 47.875em) {
    flex-direction: column-reverse;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1.8;
`;

const Title = styled.h3`
  font-size: 3.5rem;
  font-weight: 500;
  color: #fff;
`;

const Paragraph = styled.p`
  font-size: 1.4rem;
  font-weight: 300;
  color: #fff;
  margin-top: 1rem;
`;

const Image = styled.img`
  border-radius: 0.75rem;
  max-height: 25rem;
  flex: 1;
`;
