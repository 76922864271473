import styled from "styled-components";
import { BackButton } from "../../components/back-button/back-button.component";

export const TNCPage = () => {
  return (
    <Main>
      <BackButton />
      <Title>Terms and conditions</Title>
      <Paragraph>
        <b>Terms of Use</b>
        <br />
        This document is an electronic record in terms of the Information
        Technology Act, 2000 and rules there under as applicable and various
        other statutes. This electronic record is generated by a computer system
        and does not require any physical or digital signatures.
        <br />
        <br />
        This is a legal and binding agreement between you, the user ("User" or
        "You") of the Services, as defined below, and stating the terms that
        govern Your use of the Site or the Platform, as defined below. BY
        ACCEPTING THESE TERMS OF USE IN ANY MANNER OR ACCESSING THIS WEBSITE,
        YOU CONSENT, AGREE AND UNDERTAKE TO ABIDE, BE BOUND BY AND ADHERE TO THE
        TERMS OF USE and the Privacy Policy accessible at www.pocketfilms.in
        respectively and any other terms which govern the Site (together
        referred to as "Terms of Use" or "Terms" or "Terms and Conditions") AND
        IF YOU DO NOT AGREE TO THESE TERMS OF USE, YOU ARE NOT ENTITLED TO AVAIL
        OF / USE THE SERVICES AND ANY USE THEREAFTER SHALL BE UNAUTHORIZED.
        <br />
        <br />
        <b>Description of Service and Acceptance of Terms of Use</b>
        <br />
        Onetakemedia.com Private Limited, is a company incorporated under the
        Companies Act, 1956, having its registered office at 502, Shiv Tirth No,
        2, Bhulabhai Desai Road, Mumbai 400026 and its administrative office at
        #2, Plot No. 2, Udyog Nagar, S. V. Road, Goregaon West, Mumbai 400062,
        India ("Pocket Films"). Pocket Films inter alia provides an online
        website www.pocketfilms.in as an interactive platform for its viewers
        and any associated mobile sites, applications ("Site") to inter alia
        view content including but not limited to short films, documentaries,
        independent films, videos, profiles of films, people, companies, film
        festivals, contests, events, etc. on the Site and any other features,
        tools, applications, materials, or other services offered from time to
        time by Pocket Films in connection with its business, provided on the
        Site ("Content") and includes posting of comments, participation in
        contests, offering content for distribution, watching content, etc. (all
        such features of the Site collectively referred to as "Services").
        Content will be made available to You through different modes, at the
        discretion of Pocket Films, including via video on demand basis for
        viewing at the User's discretion at a time chosen by You and/or download
        for offline viewing by You. For the Content that will be made available
        to You, You will be granted a non-exclusive, non-transferable,
        non-sublicensable, revocable limited license to view the Content for
        your personal, non-commercial viewing, and that no right, title or
        interest in any Content will be deemed transferred to You. Please note
        that the availability of, and Your ability to access, the Content or
        some part of Services, (a) is subject to Pocket Films's sole discretion
        and (b) may be dependent upon Your geographical location and (c) is on
        the condition that not all Content or Services will be available to all
        viewers. On account of the nature of the Internet, this Site and the
        Services may also be accessed in various geographical locations; and You
        hereby agree and acknowledge that You are accessing this Site and
        availing of the Services, at Your own risk, choice and initiative and
        You agree and undertake to ensure that Your use of the Site and the
        Services complies with all applicable laws including the local laws in
        Your jurisdiction. Further, such Services and Content may vary from
        place to place, time to time and device to device and would be subject
        to various parameters such as specifications, device, Internet
        availability and speed, bandwidth, etc. You agree and acknowledge that
        Pocket Films, directly or through authorized entities, may monitor or
        use certain technologies for monitoring of activities including logging
        of Your IP address to ascertain and verify Your geographical location.
        To facilitate Your viewership and access, the Services can be packaged
        by Pocket Films through different models such as, Content or Services
        may be accessible (a) free of charge which may include advertisements or
        commercials or (b) via subscription through payment of a subscription
        fee or (c) a pay-per-view model with or without
        advertisements/commercials or (d) with a combination of the foregoing on
        the Site.
        <br />
        <br />
        The Service can be availed through platforms, software and devices,
        which Pocket Films approves from time to time, the current list for
        which is as set out below ("Compatible System"). In some cases, whether
        a device is (or remains) a Compatible System may depend on software or
        platform provided or maintained by the device manufacturer or other
        third parties. As a result, devices that are Compatible System at one
        time may cease to be Compatible System in the future.
        <br />
        The Pocket Films website works best with:
        <ul>
          <li>Latest version of Google Chrome</li>
          <li>Latest version of Firefox</li>
          <li>Latest version of Safari</li>
          <li>Microsoft Windows XP or above / MAC OS 10.2 or above</li>
          <li>Latest version of Adobe Flash Player</li>
          <li>JavaScript and cookies enabled</li>
        </ul>
        <br />
        <br />
        The Pocket Films applications work best with a minimum system
        requirement of:
        <ul>
          <li>Android v.4.0</li>
          <li>iOS 8</li>
        </ul>
        <br />
        <br />
        Please see details on the Site and the particular Content viewed.
        Subject to compatibility, You may be able to access the Services
        including Content through mobile phones, tablets and other IP based
        connected devices or any Compatible System offered by a partner
        authorized by Pocket Films ("Partner").
        <br />
        <br />
        Use of the Services is subject to compliance with these Terms of Use.
        <br />
        <br />
        Unless otherwise specified, the Services are available for individuals
        who have attained the age of majority in their jurisdiction; in the case
        of India, this service is restricted to Users who are aged 18 years or
        older. If You are under the relevant age of majority in Your
        jurisdiction, You may only access the Site and the Services in
        accordance with applicable law which enables a minor in your
        jurisdiction to access such a service.
        <br />
        <br />
        SOME CONTENT OFFERED ON THE SITE MAY NOT BE SUITABLE FOR SOME VIEWERS
        AND THEREFORE VIEWER DISCRETION IS ADVISED. ALSO, SOME CONTENT OFFERED
        ON THE SITE MAY NOT BE APPROPRIATE FOR VIEWERSHIP BY CHILDREN. PARENTS
        AND/OR LEGAL GUARDIANS ARE ADVISED TO EXERCISE DISCRETION BEFORE
        ALLOWING THEIR CHILDREN AND/OR WARDS TO ACCESS CONTENT ON THIS WEBSITE.
        <br />
        Your access to and use of the Services is subject to these Terms and
        Conditions, Privacy Policy and all applicable laws, rules, and
        regulations.
        <br />
        <br />
        Participation in any contest and or game on the Site conducted by Pocket
        Films or viewing of certain Content may be subject to further terms and
        conditions as may be notified by Pocket Films from time to time on the
        Site or otherwise.
        <br />
        <br />
        <b>Electronic Communications</b>
        <br />
        When You use/avail the Services or send any data, information or
        communication to Pocket Films, You agree and understand that You are
        communicating with Pocket Films through electronic media/ records and
        You consent to receive communications via electronic records from Pocket
        Films periodically and as and when required. Pocket Films will
        communicate with You by email or any push or other message or electronic
        records on the email address and or mobile number available with Pocket
        Films or made available to Pocket Films through a Partner which will be
        deemed adequate service of notice / electronic record.
        <br />
        <br />
        You acknowledge and specifically consent to Pocket Films or our
        respective affiliates and partners contacting you using the contact
        information you have provided to us at any time during your association
        with us for any purpose including but not limited to the following
        purposes:
        <ul>
          <li>To obtain feedback regarding the Service;</li>
          <li>
            To contact you for promotions and offering new products or services,
            whether offered by us, or our respective affiliates or partners.
          </li>
        </ul>
        <br />
        <br />
        You acknowledge and agree that you may be contacted for the above
        purposes, using any of the contact details provided by You, including
        via emails, social media, text messages or phone calls.
        <br />
        <br />
        In the event You have indicated any 'do not disturb' preferences to Your
        telecom service provider, and registered Yourself on the national
        customer preference register / 'do not disturb' or similar list, We
        request You to ensure that Your registered preferences allow for
        communications to be sent to You in accordance with the above
        provisions.
        <br />
        <br />
        The Content contained in the Services including but not limited to on
        the Site is protected by copyright, trademark, patent, trade secret and
        other laws, and shall not be used except as provided in these Terms and
        Conditions, without the written permission of Pocket Films.
        <br />
        <br />
        <b>Subscription Services</b>
        <br />
        Some of the Content made available on the Site can be availed only by
        Registered Users who have registered under a subscription model
        ("Subscription User"). You may need to register Yourself on the Site to
        avail these services on a subscription model ("Subscription"). To become
        a Subscription User, You may be required to provide certain personal
        information to Pocket Films and may be required to make payments
        ("Subscription Fee") as per the applicable membership plan through
        payment methods accepted by Pocket Films ("Payment Method(s)") that You
        opted for during registration. Pocket Films will be using third party
        payment gateways to process and facilitate the payment of Your
        Subscription Fee to Pocket Films. On successful registration to the
        Subscription, Pocket Films will intimate You of the same. Pocket Films
        may offer different kinds of Subscription plans, each of these
        Subscription plans will be subject to different limitations and
        restrictions and the cost of each of these Subscription plans may vary.
        <br />
        <br />
        Pocket Films shall have the discretion to make certain Content that is a
        part of the Subscription available to You on more than one end user
        Compatible System concurrently. Pocket Films shall also have the
        discretion to make certain Content that is a part of the Subscription
        available to You on not more than one end user device concurrently.
        <br />
        <br />
        <b>Free Trial</b>
        <br />
        Some of the Subscription plans may start with a free trial. The free
        trial period may last for a period of one month or such other term as
        may be determined by Pocket Films. Your eligibility for a free trial
        including the time for such free trial and the Content available to be
        viewed during free trial period is at the discretion of Pocket Films and
        such free trial can be modified, suspended or terminated by Pocket Films
        at any point of time without giving notice to You.
        <br />
        <br />
        In case You are eligible for any free trial, Pocket Films may charge You
        a token amount of money at the time of Your registration for the
        Subscription plan. This token amount will be refunded to You once Pocket
        Films receives the confirmation from the third-party payment gateway
        provider that Your Payment Method has been registered.
        <br />
        <br />
        <b>Payments</b>
        <br />
        Pocket Films will automatically start billing as per Your selected
        Payment Method for the Subscription plan elected by You at the time of
        registration through the third party payment gateway at the end of the
        free trial period, if applicable, unless You cancel the Subscription
        before the end of the free trial period. The cancellation of the
        Subscription can be done through the "My Account" section of the Site.
        It may take a few days for Your payment made to Pocket Films to be
        reflected in your "My Account" section.
        <br />
        <br />
        In case You cancel Your Subscription before the end of the free trial
        period, You will not be entitled to any compensation monetary or
        otherwise from Pocket Films for the unutilized period of free trial.
        <br />
        <br />
        You cannot change your Payment Method during the tenure of your
        Subscription. If Your selected Payment Method is no longer available or
        expires or Your Payment Method fails for whatsoever reason, You will be
        responsible and liable for any uncollected amounts and Pocket Films
        reserves the right to terminate the Subscription offered to You.
        <br />
        <br />
        The Subscription Fee will be billed at the beginning of Your
        Subscription membership or at the end of free trial period, as
        applicable and on each subsequent renewal automatically (as per the
        Subscription plan chosen by You) unless and until You cancel Your
        Subscription or the Subscription is otherwise suspended or discontinued
        pursuant to these Terms. To see the commencement date for Your next
        renewal period or to cancel Your subscription to the Subscription, You
        need to visit the "My Account" section on Your profile page. On
        cancellation of Subscription You will be notified by Pocket Films.
        <br />
        <br />
        Pocket Films will be automatically billing as per Your Payment Method
        for each Subscription period on a monthly basis. However, if You change
        Your membership plan, this could result in changing the day on which You
        are billed and the amount which You are billed.
        <br />
        <br />
        In case of change in the Subscription Fee for a particular membership
        plan that is already opted by You, Pocket Films will give You an advance
        notice of these changes.
        <br />
        <br />
        The Subscription Fees specified for each Subscription plan are exclusive
        of applicable taxes.
        <br />
        <br />
        The Subscription Fees billed are non-refundable irrespective of whether
        the Subscription have been availed by You or not.
        <br />
        <br />
        Any changes in the Subscription Plan opted by You is effective only
        after the expiry of the current Subscription period for which You have
        already been billed. Accordingly, the Subscription as per revised plan
        opted by You shall be effective only after the expiry of the then
        current Subscription period.
        <br />
        <br />
        Any request for change or cancellation in any Subscription plan prior to
        the expiration of the current Subscription plan period will not entail
        You with a refund for any portion of the Subscription Fee paid by You
        for the unexpired period.
        <br />
        <br />
        You also understand and acknowledge that Pocket Films only facilitates
        the third-party payment gateway for processing of payments. This
        facility is managed by the third party payment gateway provider and You
        are required to follow all the terms and conditions of such third party
        payment gateway provider.
        <br />
        <br />
        You are responsible for the accuracy and authenticity of the information
        provided by You, including the bank account number/credit card details
        and the like. You agree and acknowledge that Pocket Films shall not be
        liable and in no way be held responsible for any losses whatsoever,
        whether direct, indirect, incidental or consequential, including without
        limitation any losses due to delay in processing of payment instruction
        or any credit card fraud.
        <br />
        <br />
        You can file any complaint related to payment processing on the Site and
        the same will be forwarded to the concerned third party payment gateway
        provider for redressal.
        <br />
        <br />
        Upon payment being received by Pocket Films through any of the Payment
        Method, Pocket Films shall make the Subscription available to You
        through any of the approved modes of delivery that Pocket Films adopts.
        <br />
        <br />
        It is hereby clarified that the Subscription offered to You are offered
        by Pocket Films and not by any third party including any payment gateway
        service providers.
        <br />
        <br />
        Pocket Films reserves the right to change, terminate or otherwise amend
        the Subscription plans, Subscription Fees for the Subscription and
        billing cycles at its sole discretion and at any time. Such amendments
        shall be effective upon posting on the Site and Your continued use of
        the Service shall be deemed to be Your conclusive acceptance of such
        amendments.
        <br />
        <br />
        Pocket Films reserves the right to change, supplement, alter or remove
        any of the Content as it deems fit. Pocket Films does not guarantee the
        availability of a specific Content or any minimum Content .
        <br />
        <br />
        <b>Access and Use of the Services</b>
        <br />
        Pocket Films grants You a non-exclusive, revocable and limited
        permission to use the Services on the Site for personal, non-commercial
        purposes during the subsistence of Your Account with Pocket Films or
        Partner for the territory of the world or limited territories as
        applicable in respect of specified Content and as set forth in these
        Terms and Conditions.
        <br />
        <br />
        By agreeing to use/avail the Services:
        <br />
        <br />
        You confirm and warrant that all the data and information supplied by
        You when You register (if applicable) and provided by You to Pocket
        Films and or any Pocket Films's affiliates, distributors ("Registration
        Data") is true, current, complete and accurate in all respects.
        <br />
        <br />
        You agree to promptly update Your Registration Data, so that Your
        information remains true, current, complete, and accurate at all times.
        <br />
        <br />
        You acknowledge and agree that Your Registration Data and any other
        personal data collected by Pocket Films is subject to Pocket Films's
        Privacy Policy. For more information, view the full Privacy Policy.
        Pocket Films may collect information including but not limited to
        occupation, language, pincode, area code, unique device identifier,
        location, and the time zone where the Services are used and may use
        these to target advertising and make content recommendations.
        <br />
        <br />
        You acknowledge, consent and agree that Pocket Films may access,
        preserve, transfer and disclose Your account information and/or User
        Material (see below) subject to the Privacy Policy, if required to do so
        by law to co-operate with mandated government and law enforcement
        agencies or to any private parties by an order under law for the time
        being in force to enforce and comply with the law including to various
        tax authorities upon any demand or request by them or in a good faith
        belief that such access, preservation, or disclosure is reasonably
        necessary to: (a) comply with legal process and/or any applicable law in
        the territory; (b) enforce the Terms and Conditions; (c) respond to Your
        requests for customer service; or (d) protect the rights, property or
        personal safety of Pocket Films, affiliates, its users and the public.
        <br />
        <br />
        You agree that Pocket Films has the right to temporarily suspend access
        to the whole or any part of the Services for any technical/operational
        reason and shall be under no liability to You in such an event. Pocket
        Films may, but shall not be obliged to, give You as much notice of any
        interruption of access to the Services as is reasonably practicable.
        Pocket Films will restore access to the Services as soon as reasonably
        practicable after temporary suspension.
        <br />
        <br />
        You agree that Pocket Films shall be under no liability whatsoever to
        You in the event of non-availability of the Site or any portion thereof
        for any reason whatsoever or may be occasioned by Act of God, war,
        disease, revolution, riot, civil commotion, strike, lockout, flood,
        fire, satellite failure, failure of any public utility, man-made
        disaster, satellite failure or any other cause whatsoever beyond the
        control of Pocket Films.
        <br />
        <br />
        You acknowledge and agree not to either directly or through the use of
        any device, software, internet site, web-based service, or other means
        remove, alter, bypass, avoid, interfere with, violate, or circumvent any
        patent; trade secret; copyright, trademark, or other proprietary notice
        marked on the Content or any digital rights management mechanism or
        device; any content protection or access control measure associated with
        the Content, including geo-filtering mechanisms; privacy; publicity; or
        other proprietary right under applicable law.
        <br />
        <br />
        You agree not to either directly or through the use of any device,
        software, internet site, web-based service, or other means copy,
        download, capture, reproduce, duplicate, archive, distribute, upload,
        publish, modify, translate, broadcast, perform, display, sell, transmit
        or retransmit the Content or create any work or material that is derived
        from or based on the Content. This prohibition applies regardless of
        whether such derivative works or materials are sold, bartered, or given
        away.
        <br />
        <br />
        You agree that Pocket Films and/or its affiliates or licensors owns
        and/or retains all rights to the Services and the Content throughout the
        territory of the world in perpetuity. You further agree that the Content
        You access and view as part of the Services is owned or controlled by
        Pocket Films, its affiliates and or its licensors. The Services and the
        Content are protected by copyright, trademark, design, patent, trade
        secret, and other intellectual property laws.
        <br />
        <br />
        Except as provided in these Terms (or otherwise expressly provided by
        Pocket Films), You may not copy, download, stream capture, reproduce,
        duplicate, archive, upload, modify, translate, publish, broadcast,
        transmit, retransmit, distribute, perform, display, sell, frame or
        deep-link, make available, or otherwise use any Content contained in the
        Services. You further agree not to use the Services to: <br />
        - Post, upload or otherwise transmit or link to Content that is:
        unlawful; threatening; abusive; obscene; vulgar; sexually explicit;
        pornographic or inclusive of nudity; offensive; excessively violent;
        invasive of another's privacy, publicity, contract or other rights;
        tortious; false or misleading; defamatory; libelous; hateful; or
        discriminatory;
        <br />
        - Harass or harm another person;
        <br />
        - Exploit or endanger a minor;
        <br />
        - Impersonate or attempt to impersonate any person or entity;
        <br />
        - Violate any patent, trademark, trade secret, copyright, privacy,
        publicity or other proprietary right;
        <br />
        - Introduce or engage in activity that involves the use of viruses,
        software disabling codes, bots, worms, or any other computer code, files
        or programs that interrupt, destroy, or limit the functionality of any
        computer software or hardware or telecommunications equipment, or
        otherwise permit the unauthorized use of or access to a computer or a
        computer network;
        <br />
        - Attempt to decipher, decompile, disassemble or reverse engineer any of
        the software, applications and/or any element comprising the Site, the
        application for accessing the Content, or the Pocket Films Services;
        <br />
        - Interfere with, damage, disable, disrupt, impair, create an undue
        burden on, or gain unauthorized access to the Pocket Films Services,
        including Pocket Films's servers, networks or accounts;
        <br />
        - Cover, remove, disable, manipulate, block or obscure advertisements or
        other portions of the Pocket Films Services or the Content;
        <br />
        - Delete or revise any information provided by or pertaining to any
        other user of the Pocket Films Services.
        <br />
        - Use technology or any automated system such as scripts, spiders,
        offline readers or bots in order to collect or disseminate usernames,
        passwords, email addresses or other data from the Pocket Films Services,
        or to circumvent, delete, deactivate, decompile, reverse engineer,
        disassemble or modify any security technology or software that is part
        of the Pocket Films Services or the Site;
        <br />
        - Send or cause to send (directly or indirectly) unsolicited bulk
        messages or other unsolicited bulk communications of any kind through
        the Pocket Films Services. If You do so, You acknowledge You will have
        caused substantial harm to Pocket Films, but that the amount of harm
        would be extremely difficult to measure. As a reasonable estimation of
        such harm, and by way of liquidated damages and not as a penalty, You
        agree to pay Pocket Films a minimum of INR 5000 (Indian rupees five
        thousand only) for each actual or intended recipient of such
        communication without prejudice to any other rights of action that
        Pocket Films may have against You in law or equity;
        <br />
        - Solicit, collect or request any personal information for commercial or
        unlawful purposes;
        <br />
        - Post, upload or otherwise transmit an image or video of another person
        without that person's consent;
        <br />
        - Engage in commercial activity (including but not limited to
        advertisements or solicitations of business; sales; contests;
        sweepstakes; creating, recreating, distributing or advertising an index
        of any significant portion of the Content; or building a business using
        the Content) without Pocket Films's prior written consent;
        <br />
        - Use technology or other means to access, index, frame, or link to the
        Site (including the Content) that is not authorized by Pocket Films;
        <br />
        - Access the Site/application/Service (including the Content) through
        any automated means, including "robots," "spiders," or "offline readers"
        (other than by individually performed searches on publicly accessible
        search engines for the sole purpose of, and solely to the extent
        necessary for, creating publicly available search indices - but not
        caches or archives - of the Site and excluding those search engines or
        indices that host, promote, or link primarily to infringing or
        unauthorized content);
        <br />
        - Use the Services to advertise or promote competing services;
        <br />
        - Use the Services in a manner inconsistent with any and all applicable
        law;
        <br />
        - Attempt, facilitate, induce, aid and abet, or encourage others to do
        any of the foregoing.
        <br />
        <br />
        You are responsible for the security and proper use of Your unique user
        ID and password that You enter while registering on the Site or on the
        Partner's website or application and must take all necessary steps to
        ensure that they are kept confidential, used properly, and not disclosed
        to unauthorized people.
        <br />
        <br />
        You agree that Your use of the Services and the Content may give You the
        opportunity to view, publish, transmit, submit posts and/or comment in
        accordance with these Terms and Conditions. You agree that You will not
        misuse the Content and/or other user material You access and view as
        part of the Service, including without limitation, download/store the
        Content by illegal/non-permitted means, or infringe any of Pocket
        Films's/ or its licensor's copyright, trademark design, patent, and
        other intellectual property including any rights of Pocket Films or its
        licensors subsisting in the Content. You further agree that You will not
        copy/record/edit/modify any of the Content You access and view as part
        of the Service, for any purpose, and/or copy/record/edit/modify any
        Content including video, images etc., by using any means including
        software, spyware, etc., for any purpose whatsoever.
        <br />
        <br />
        You further agree that You shall not post, publish, transfer, upload,
        transmit or submit any of the Content, including without limitation
        videos, images, comments, User Material (defined below) or articles, of
        the Site on any other website, webpage or software, whatsoever which is
        not owned by Pocket Films.
        <br />
        <br />
        You agree that the Content provided on the Site is strictly for Your
        private viewing only and not for public exhibition irrespective of
        whether the public is charged for such exhibition or not. You hereby
        agree to refrain from further broadcasting or making available the
        Content provided on the Site to any other person(s), or in any manner
        communicate such Content to the public.
        <br />
        <br />
        In case the Site allows You to download or stream any of the Content
        therein, You agree that You will not use, sell, transfer or transmit the
        Content to any person or upload the Content on any other website,
        webpage or software, which may violate/cause damages or injuries to any
        rights of Pocket Films or its affiliates or any third party including
        privacy rights, publicity rights, and intellectual property rights.
        <br />
        <br />
        You may encounter third party applications while using the Site
        including, without limitation, websites, widgets, software, services
        that interact with the Site. Your use of these third party applications
        shall be subject to such third party terms of use or license terms.
        Pocket Films shall not be liable for any representations or warranties
        or obligations made by such third party applications to You under
        contract or law.
        <br />
        <br />
        <b>Termination of Service</b>
        <br />
        Pocket Films reserves the right to immediately terminate, suspend,
        limit, or restrict Your account or Your use of the Services or access to
        Content at any time, without notice or liability, if Pocket Films so
        determines in its sole discretion, for any reason whatsoever, including
        that You have breached these Terms and Conditions, the Privacy Policy,
        violated any law, rule, or regulation, engaged in any inappropriate
        conduct, provided false or inaccurate information, or for any other
        reason. You hereby agree and consent to the above and agree and
        acknowledge that Pocket Films can, at its sole discretion, exercise its
        right in relation to any or all of the above, and that Pocket Films, its
        directors, officers, employees, affiliates, agents, contractors,
        principals or licensors shall not be liable in any manner for the same;
        and You hereby agree, acknowledge and consent to the same.
        <br />
        <br />
        User Reviews, Comments and Other Material
        <br />
        <br />
        You may have an opportunity to publish, transmit, submit, or otherwise
        post (collectively, "Post") reviews, votes or comments (collectively, "
        User Material"). As it concerns User Material, without prejudice to Your
        obligation to otherwise comply with applicable laws during the course of
        using the Services, You agree to hereby comply with any and all
        applicable laws, as well as any other rules and restrictions that may be
        set forth herein or on the Site or Services.
        <br />
        <br />
        You agree that Pocket Films shall have the right but have no obligation,
        to monitor User Material and to restrict or remove User Material that
        Pocket Films may determine, in its sole discretion, is inappropriate or
        for any other reason. You acknowledge that Pocket Films reserves the
        right to investigate and take appropriate legal action against anyone
        who, in Pocket Films's sole discretion, violates these Terms, including,
        but not limited to, terminating their account, terminate, suspend,
        limit, or use of the Services or access to Content  and/or reporting
        such User Material, conduct, or activity, to law enforcement
        authorities, in addition to any other available remedies under law or
        equity. In addition to the restrictions set forth above, You must adhere
        to the below terms and conditions and not host, display, upload, modify,
        publish, transmit, update or share any information /material/User
        Material that: <br />
        <ul>
          <li>
            belongs to another person and to which You do not have any right to;
          </li>
          <li>
            is grossly harmful, harassing, blasphemous, defamatory, obscene,
            pornographic, pedophilic, libelous, invasive of another's privacy,
            hateful, or racially, ethnically objectionable, disparaging,
            relating or encouraging money laundering or gambling, or otherwise
            unlawful in any manner whatsoever;
          </li>
          <li>harms minors in any way;</li>
          <li>
            infringes any patent, trademark, design, copyright or other
            proprietary rights;
          </li>
          <li>violates any law for the time being in force;</li>
          <li>
            deceives or misleads the addressee about the origin of such messages
            or communicates any information which is grossly offensive or
            menacing in nature;
          </li>
          <li>impersonates another person;</li>
          <li>
            contains software viruses or any other computer code, files or
            programs designed to interrupt, destroy or limit the functionality
            of any computer resource;
          </li>
          <li>
            threatens the unity, integrity, defense, security or sovereignty of
            India, friendly relations with foreign states, or public order or
            causes incitement to the commission of any cognizable offence or
            prevents investigation of any offence or is insulting any other
            nation. You also represent and warrant that the Posting of Your User
            Material does not violate any right of any party, including privacy
            rights, publicity rights, and intellectual property rights.
          </li>
        </ul>
        <br />
        <br />
        You acknowledge that the User Material is not confidential and that You
        have no expectation of privacy in it.
        <br />
        <br />
        By posting the User Material, You hereby grant Pocket Films a license to
        use, display, reproduce, distribute, modify, delete from, add to,
        prepare derivative works of, publicly perform, and publish such User
        Material worldwide, including on or through any property, in perpetuity,
        in any media formats and any media channels now known or hereinafter
        created. The license You grant to Pocket Films is non-exclusive,
        royalty-free, irrevocable, and sub licensable. Additionally, Pocket
        Films is free to use any ideas, concepts, know-how, or techniques
        contained within such User Material for any purpose, including, but not
        limited to, developing, manufacturing, marketing, and providing
        commercial products and services, including the Services. Pocket Films's
        use of such User Material shall not require any further notice of
        attribution to You and such use shall be without requirement of any
        permission from or any payment to You or any other person or entity. You
        hereby agree and confirm that the provisions of section 19(4) of the
        Indian Copyright Act, 1957, shall not apply to this license.
        <br />
        <br />
        In no event does Pocket Films assume any responsibility or liability
        whatsoever for any User Material, and You agree to waive any legal or
        equitable rights or remedies You may have against Pocket Films with
        respect to such User Material.
        <br />
        <br />
        If You have any complaints relating to any third party User Material or
        Content uploaded on the Site, then please contact info@pocketfilms.in
        with / through Your email, which must bear Your digital signature or a
        signed written complaint, should include the following information:
        <br />
        <br />
        Details Of The Objectionable User Material Or Content;
        <br />
        <br />
        Details Of The Basis Of The Objection;
        <br />
        <br />
        What Relief Is Being Requested;
        <br />
        <br />
        All Other Relevant Information Required By Pocket Films To Assess The
        Complaint.
        <br />
        <br />
        Pocket Films shall on examination of the complaint take all reasonable
        measures, including taking down the objectionable User Material
        expeditiously in accordance with applicable law. You agree and
        acknowledge that Pocket Films's responsibility in relation to
        objectionable User Material is limited to removing them post receipt of
        Your objections in accordance with these Terms of Use and Pocket Films
        shall not be responsible or liable for any other damages or losses
        incurred by You of any nature whatsoever.
        <br />
        <br />
        <b>Linked Destinations and Advertising </b>
        <br />
        <br />
        Part of this Site/Service contains advertising and other material
        including data submitted to us by third parties and/or links to third
        party sites and third party content. Although display of advertisements
        on the Site on request is subject to our terms and conditions, we
        expressly disclaim any liability arising out of the usage or viewing of
        these products or services advertised on our Site or the (third party)
        content made available / hosted on the third party sites. You agree and
        acknowledge that Pocket Films does not endorse or sponsor such third
        party sites, content, advertising or other material. This Site may
        contain articles contributed by several individuals. The views are
        exclusively their own and do not represent the views of Pocket Films,
        affiliates and/ or its management. All liability in respect of the above
        is excluded to the extent permitted by law including any implied terms.
        Indian law and jurisdiction applies with respect to contents of this
        Site. While using this website for information purposes or otherwise,
        users acknowledge and accept the above disclaimer.
        <br />
        <br />
        If Pocket Films provides links or pointers to other websites or
        destinations, You should not infer or assume that Pocket Films operates,
        controls, or is otherwise connected with these other websites or
        destinations. When You click on a link within the Services, Pocket Films
        will not warn You that You have left the Services and are subject to the
        terms and conditions (including privacy policies) of another website or
        destination. In some cases, it may be less obvious than others that You
        have left the Services and reached another website or destination.
        Please be careful to read the terms of use and privacy policy of any
        other website or destination before You provide any confidential
        information or engage in any transactions. You should not rely on these
        Terms and Conditions and/or Privacy Policy to govern Your use of another
        website or destination.
        <br />
        <br />
        Pocket Films is not responsible for the content or practices of any
        website or destination other than the Site, even if it links to the Site
        and even if the website or destination is operated by a company
        affiliated or otherwise connected with Pocket Films. By using the
        Services, You acknowledge and agree that Pocket Films is not responsible
        or liable to You for any content or other materials hosted and served
        from any website or destination other than the Site.
        <br />
        <br />
        Pocket Films takes no responsibility for advertisements or any third
        party material posted on the Site nor does it take any responsibility
        for the products or services provided by advertisers. Any dealings You
        have with advertisers found while using the Services are between You and
        the advertiser, and You agree that Pocket Films is not liable for any
        loss or claim that You may have against an advertiser.
        <br />
        <br />
        <b>Support</b>
        <br />
        <br />
        If You have any questions, complaints or claims with respect to the Site
        and/or Services, then such correspondence should be directed to the
        address as given below.
        <br />
        <br />
        Pocket Films, Gala No. 2, Udyog Nagar, S. V. Road, Goregaon West,
        Mumbai, 400062 <br />
        The contact information: info@pocketfilms.in
        <br />
        <br />
        <b>Disclaimer of Warranties </b>
        <br />
        You agree that use of the Services and the access to the Site is at Your
        own risk. The Services including the Site, the Content, the video
        player, User Material and any other materials contained on or provided
        through the Site are provided "AS IS" and, to the fullest extent
        permitted by law, are provided without warranties of any kind, either
        express or implied. Without limiting the foregoing, Pocket Films does
        not make any warranties of fitness for a particular purpose, title,
        merchantability, completeness, availability, security, compatibility or
        non-infringement; or that the Services will be uninterrupted, free of
        viruses and other harmful components, accurate, error free or reliable.
        <br />
        <br />
        Limitation of Liability
        <br />
        <br />
        IN NO CASE SHALL POCKET FILMS, ITS DIRECTORS, OFFICERS, EMPLOYEES,
        AFFILIATES, AGENTS, CONTRACTORS, PRINCIPALS, OR LICENSORS BE LIABLE FOR
        ANY DIRECT, INDIRECT, INCIDENTAL, PUNITIVE, SPECIAL, OR CONSEQUENTIAL
        DAMAGES ARISING FROM YOUR USE OF THE SERVICES OR ANY CONTENT OR MATERIAL
        THEREOF OR FOR ANY OTHER CLAIM RELATED IN ANY WAY TO YOUR ACCESS OF THE
        SITE OR USE OF THE SERVICES OR ANY CONTENT OR MATERIAL THEREOF,
        INCLUDING, BUT NOT LIMITED TO, ANY ERRORS OR OMISSIONS IN ANY CONTENT OR
        MATERIAL, OR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE
        USE OF THE SERVICES OR ANY CONTENT OR MATERIAL THEREOF, EVEN IF ADVISED
        OF THEIR POSSIBILITY. WHERE THE LAWS DO NOT ALLOW THE EXCLUSION OR THE
        LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH
        JURISDICTIONS, POCKET FILMS'S LIABILITY SHALL BE LIMITED TO THE EXTENT
        PERMITTED BY LAW.
        <br />
        <br />
        <b>Indemnity </b>
        <br />
        <br />
        <br />
        You agree to defend, indemnify and hold harmless Pocket Films, its
        affiliates, officers, directors, employees and agents, from and against
        any and all claims, damages, obligations, losses, liabilities, costs or
        debt, and expenses (including but not limited to attorneys' fees)
        arising from: (i) Your use of and access to the Services; (ii) Your
        violation of any term of these Terms and Conditions; (iii) Your
        violation of any third party right, including without limitation any
        publicity, privacy, or intellectual property right; (iv) Your breach of
        any applicable laws; and (v) any unauthorized, improper, illegal or
        wrongful use of Your Account by any person, including a third party,
        whether or not authorized or permitted by You. This indemnification
        obligation will survive the expiry or termination of these Terms and
        Conditions and Your use of the Service.
        <br />
        <br />
        Governing Law and Jurisdiction
        <br />
        <br />
        The relationship between You and Pocket Films shall be governed by the
        laws of India without regard to its conflict of law provisions and for
        resolution of any dispute arising out of Your use of the Services.
        Notwithstanding the foregoing, You agree that (i) Pocket Films has the
        right to bring any proceedings before any court/forum of competent
        jurisdiction and You irrevocably submit to the jurisdiction of such
        courts or forum and (ii) any proceeding brought by You shall be
        exclusively before the courts at Mumbai, India.
        <br />
        <br />
        Severability
        <br />
        <br />
        If any provision of these Terms and Conditions is held invalid, void, or
        unenforceable, then that provision shall be considered severable from
        the remaining provisions, and the remaining provisions given full force
        and effect.
        <br />
        <br />
        Changes
        <br />
        <br />
        Pocket Films reserves the right, at its sole discretion, to change,
        modify, add or remove any portion of this Terms of Use in whole or in
        part, at any time. Changes to the Terms of Use will be effective when
        posted. You agree to review the Terms of Use periodically to become
        aware of any changes. The use of the Site after any changes to the Terms
        of Use are posted will be considered acceptance of those changes by you
        and will constitute your agreement to be bound thereby. If you object to
        any such changes, your sole recourse will be to stop using the Site.
        <br />
        <br />
        Survival
        <br />
        <br />
        You acknowledge that Your representations, undertakings, and warranties
        and the clauses relating to indemnities, limitation of liability, grant
        of license, governing law, confidentiality shall survive the efflux of
        time and the termination of these Terms and Conditions.
        <br />
        <br />
        Entire Agreement
        <br />
        <br />
        These Terms and Conditions and Privacy Policy constitute the entire
        agreement between You and Pocket Films governing Your use of the
        Services, superseding any prior agreements between You and Pocket Films
        regarding such use.
        <br />
        <br />
        FORM OF MEMBER AGREEMENT
        <br />
        <br />
        The PocketFilms Terms of Service Agreement and Disclaimer
        <br />
        <br />
        The following provisions govern the use of www.pocketfilms.in (the
        “Site”) operated by Onetakemedia.com Private Limited (“PocketFilms”) by
        PocketFilms's members. Members agree to the following by submitting a
        member registration form. Members receive access to all the Site's
        features.
        <br />
        <br />
        Copyright and Trademarks
        <br />
        <br />
        The entire contents and design of the Site are protected under US and
        international copyrights. The Site is for your personal noncommercial
        use. You may not modify, copy, distribute, transmit, display, or publish
        any materials contained on the Site without the prior written permission
        of PocketFilms or the appropriate copyright owner. Please refer to the
        copyright notice for more information. Certain names and logos are
        trademarks and service marks of PocketFilms and third parties and may
        not be used without permission.
        <br />
        <br />
        Disclaimer
        <br />
        <br />
        THE SITE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT
        WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT
        LIMITED TO, WARRANTIES OF TITLE, OR IMPLIED WARRANTIES OF
        MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.
        <br />
        <br />
        YOUR USE OF THE SITE IS AT YOUR OWN RISK.
        <br />
        <br />
        ACCESS TO THE SITE MAY BE INTERRUPTED AND INFORMATION PROVIDED MAY NOT
        BE ERROR FREE.
        <br />
        <br />
        NEITHER POCKETFILMS, THE LICENSOR(S) / THE CONTENT PROVIDER(S)NOR ANYONE
        ELSE INVOLVED IN CREATING, PRODUCING OR DELIVERING THE SITE OR THE
        MATERIALS CONTAINED THEREIN, ASSUMES ANY LIABILITY OR RESPONSIBILITY FOR
        THE ACCURACY, COMPLETENESS, OR USEFULNESS OF ANY INFORMATION PROVIDED ON
        THE SITE, NOR SHALL THEY BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
        SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES ARISING OUT OF YOUR USE OF,
        OR INABILITY TO USE, THE SITE.
        <br />
        <br />
        The “Content Provider” provides editorial content to PocketFilms. Their
        Content(s) are designed to provide entertainment and/or general
        information in regard to a subject matter covered. It should not be
        considered as expert advice or expert assistance under any
        circumstances.
        <br />
        <br />
        The content available through the Site from the Content Provider is the
        property of the Content Provider and is protected by copyright and other
        intellectual property laws. Content published by Content Provider may be
        displayed, reformatted and printed for your personal, non-commercial use
        only. You agree not to reproduce, retransmit, distribute, display, sell,
        publish, broadcast or circulate any content published by the Content
        Provider to anyone, including but not limited to others in the same
        Content Provider or organization, without the express prior consent of
        the Content Provider, with the following exception:
        <br />
        <br />
        You may, on an occasional and irregular basis, disseminate an
        unsubstantial portion of content published by the Content Provider, for
        a noncommercial purpose, without charge, and in non- electronic form, to
        a limited number of individuals, provided you include all copyright and
        other proprietary notices with such portion of the content in the same
        form in which the notices appear in the Content, and you have taken
        prior written permission from Pocket Films and agree to add the phrase
        “Used with permission from the Content Provider.”
        <br />
        <br />
        Indemnification
        <br />
        <br />
        You agree to hold harmless and indemnify the Content Provider and
        PocketFilms, their owners and agents, and the contributors to the Site,
        against any liability for any claims and expenses, including reasonable
        attorney's fees, relating to any violation of the terms of this
        Agreement or arising out of any materials submitted by you.
        <br />
        <br />
        Governing Law; Jurisdiction; Statute of Limitations
        <br />
        <br />
        This Agreement shall be governed by the laws of the Republic of India
        applicable to agreements wholly made and performed in India without
        regard to its conflict of laws principle. Before seeking legal recourse
        for any harm you believe you have suffered from your access to the Site,
        you will give PocketFilms written notice specifying the harm and 30 days
        to cure the harm after providing such notice. In the event that you
        believe you have been irreparably harmed by any cause of action relating
        to PocketFilms, you agree to inform the Content Provider in writing and
        grant PocketFilms 30 days to cure the harm before initiating any action.
        Any legal action, suit or proceeding arising out of or relating to this
        Agreement or a breach thereof, shall be instituted in a court of
        competent jurisdiction in MUMBAI and you hereby consent and submit to
        personal jurisdiction of such court, waive any objection to venue in
        such court and consent to service of process by overnight courier or
        express mail at your last known address. Any cause of action initiated
        by you must be initiated within one year after the claim or cause of
        action has arisen or it will be barred.
        <br />
        <br />
        Acceptance Procedure
        <br />
        <br />
        By submitting PocketFilms membership application form or accessing
        materials on the Site you agree with all the terms and conditions of
        this Agreement. You further agree that this agreement and the terms and
        conditions hereof, including all disclaimers set forth herein, shall
        inure to the benefit of the editors and their agents, and the
        contributors to the Site, as third-party beneficiaries hereto, to the
        same extent that this agreement inures to the benefit of PocketFilms.
        <br />
        <br />
        PocketFilms reserves the right, at its sole discretion, to change the
        terms and conditions of this Agreement from time to time and your access
        to the Site will be deemed to be your acceptance of and agreement to any
        changed terms and conditions.
        <br />
        <br />
        PocketFilms may contain links to web sites operated by other parties.
        Such links do not imply PocketFilms's endorsement of material on any
        other site and PocketFilms disclaims all liability with regard to your
        access of such linked web sites.
      </Paragraph>
    </Main>
  );
};

const Main = styled.div`
  width: 100vw;
  min-height: 100vh;
  padding: 4rem;
`;

const Title = styled.h2`
  font-size: 3.8rem;
  font-weight: 600;
  color: #fff;
  text-align: center;
`;

const Paragraph = styled.div`
  font-size: 1.4rem;
  font-weight: 300;
  color: #fff;
  text-align: justify;
`;
