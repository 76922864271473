import axiosLib from "axios";
import { config } from "../config/config";

const axios = axiosLib.create({
  baseURL: config.api_url,
  withCredentials: true,
});

axios.interceptors.request.use(async (axiosConfig) => {
  let csrfToken = sessionStorage.getItem("csrf_token");
  if (!csrfToken) {
    const result = await axiosLib.get<{ csrfToken: string }>(
      `${config.api_url}/auth/get/csrf`
    );

    csrfToken = result.data.csrfToken;
    sessionStorage.setItem("csrf_token", csrfToken);
  }

  return {
    ...axiosConfig,
    headers: {
      ...axiosConfig.headers,
      "X-CSRFToken": csrfToken,
    } as any,
  };
});

export { axios };
