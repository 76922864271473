import { Link } from "react-router-dom";
import { styled } from "styled-components";

export const FooterSection = () => {
  return (
    <Container>
      <div>
        <EmailTitle href="mailto:info@pocketfilms.in">
          info@pocketfilms.in
        </EmailTitle>
        <Paragraph>
          Email us or <a href="http://wa.me/+917700904810">WhatsApp us</a>
        </Paragraph>
      </div>
      <div>
        <Paragraph>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </Paragraph>
        <Paragraph>
          <Link to="/terms-and-conditions">Terms and Conditions</Link>
        </Paragraph>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100vw;
  margin-top: 8rem;
  padding: 4rem 8rem;
  background-color: #eee;

  @media only screen and (max-width: 46.875em) {
    flex-direction: column;
  }
`;

const EmailTitle = styled.a`
  font-size: 3.5rem;
  font-weight: 500;
  color: #205496;
  cursor: pointer;
  text-decoration: none;
`;

const Paragraph = styled.p`
  font-size: 1.4rem;
  font-weight: 300;
  color: #040c15;
  margin-top: 1rem;

  & a {
    color: #205496;
    font-weight: 375;
    text-decoration: none;
    cursor: pointer;
  }
`;
