import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";

export const FAQSection = () => {
  const navigate = useNavigate();

  return (
    <Main>
      <Title>Have questions?</Title>
      <Paragraph>Check out our FAQs.</Paragraph>
      <Button
        type="primary"
        style={{ marginTop: "1rem" }}
        size="large"
        onClick={() => navigate("/faq")}
      >
        See FAQs
      </Button>
    </Main>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  margin-top: 5rem;
`;

const Title = styled.h2`
  font-size: 3.8rem;
  font-weight: 600;
  color: #fff;
  text-align: center;
`;

const Paragraph = styled.p`
  font-size: 1.4rem;
  font-weight: 300;
  color: #fff;
  text-align: center;
`;
