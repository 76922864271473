import { styled } from "styled-components";
import { BackButton } from "../../components/back-button/back-button.component";

export const PrivacyPolicyPage = () => {
  return (
    <Main>
      <BackButton />
      <Title>Privacy Policy</Title>
      <Paragraph>
        This Privacy Policy explains our practices, including your choices,
        regarding the collection, use, and disclosure of certain information,
        including your personal information, by Pocket Films across it services
        including but not limited to its website, mobile apps and other
        applications. Pocket Films is owned by Onetakemedia.com Private Limited
        and reference to either will mean and refer to the same company.
        <br />
        <br />
        <b>Contacting Us</b>
        <br />
        If you have general questions about your account or how to contact
        Customer Service for assistance, please visit our online help center at{" "}
        <a href="www.pocketfilms.in/contactus">
          www.pocketfilms.in/contactus.
        </a>{" "}
        For questions specifically about this Privacy Policy, or our use of your
        personal information, cookies or similar technologies, please contact us
        by email at <a href="mailto:info@pocketfilms.in">info@pocketfilms.in</a>
        <br />
        <br />
        You can also write to us at the address mentioned on the Contact Us page
        on the site.
        <br />
        <br />
        The data controller of your personal information is Pocket Films, which
        also provides the service to you (address above). Please note that if
        you contact us to assist you, for your safety and ours we may need to
        authenticate your identity before fulfilling your request.
        <br />
        <br />
        <b>Collection of Information </b>
        <br />
        We receive and store information about you such as: <br />
        Information you provide to us: <br />
        We collect information you provide to us which includes:
        <ul>
          <li>
            your name, email address, postal address , tax details, bank
            details, and/or any other payment details and telephone and mobile
            number(s). We collect this information in a number of ways,
            including when you enter it while using our service, interact with
            our Customer Service, or participate in surveys or marketing
            promotions;
          </li>
          <li>
            information when you choose to provide your personal information
            including gender, age, qualifications, etc., content (including
            films, documentaries, scripts, metadata, etc.) details, comments,
            reviews or ratings, tastes / preferences, account settings, set
            preferences in Your Account, or otherwise provide information to us
            through our service or elsewhere.
          </li>
        </ul>
        <br />
        Information we collect automatically:
        <br />
        We collect information about you and your use of our service, your
        interactions with us and our advertising, as well as information
        regarding your computer or other device used to access our service This
        information includes:
        <ul>
          <li>
            your activity on the Pocket Films service, including but not limited
            to title selections, watch history and search queries;
          </li>
          <li>
            details of your interactions with Customer Service, such as the
            date, time and reason for contacting us, transcripts of any chat
            conversations, and if you call us, your phone number and call
            recordings;
          </li>
          <li>
            device IDs or other unique identifiers; device and software
            characteristics (such as type and configuration), connection
            information, statistics on page views, referral URLs, IP address
            (which may tell us your general location), browser and standard web
            server log information;
          </li>
          <li>
            information collected via the use of cookies, web beacons and other
            technologies, including ad data (such as information on the
            availability and delivery of ads, the site URL, as well as the date
            and time). See our Cookies and Internet Advertising section for more
            details.
          </li>
        </ul>
        <br />
        Information about your social media accounts / pages and information
        made available from such platforms. You further grant permission
        allowing Pocket Films to tag you / your social media handles in social
        media posts. Information from other sources:
        <br />
        We might supplement the information described above with information we
        obtain from other sources, including from both online and offline data
        providers. Such supplemental information could include but not limited
        to demographic data, interest based data, and Internet browsing
        behavior.
        <br />
        <br />
        <b>Use of Information</b>
        <br />
        We use information to provide, analyze, administer, enhance and
        personalize our services and marketing efforts, to process your
        registration, your orders and your payments, if any, including the
        information to host your data and/or content, make it available to users
        (online and offline) as well as displaying the name, photos, images,
        videos, etc. and to communicate with you on these and other topics. For
        example, we use information to:
        <ul>
          <li>
            determine your general geographic location, provide localized
            content, provide you with customized and personalized viewing
            recommendations for content we think will be enjoyable, determine
            your Internet service provider, and help us quickly and efficiently
            respond to inquiries and requests;
          </li>
          <li>
            prevent, detect and investigate potentially prohibited or illegal
            activities, including fraud, and enforcing our terms (such as
            determining free trial eligibility);
          </li>
          <li>
            analyze and understand our audience, improve our service (including
            our user interface experiences) and optimize content selection,
            recommendation algorithms and delivery;
          </li>
          <li>
            communicate with you concerning our service (for example by email,
            push notifications text messaging, and online messaging channels),
            so that we can send you news about Pocket Films, details about new
            features and content available on Pocket Films and special offers,
            promotional announcements and consumer surveys, and to assist you
            with operational requests such as password reset requests. Please
            see the "Your Choices" section of this Privacy Statement to learn
            how to set or change your communications preferences.
          </li>
        </ul>
        <br />
        <b>Disclosure of Information</b>
        <br />
        We disclose your information for certain purposes and to third parties,
        as described below:
        <br />
        <br />
        Affiliates: We share your information among our Affiliates as needed
        for: data processing and storage; providing you with access to our
        services; providing customer support; making decisions about service
        improvements, content development; and for other purposes described in
        the Use of Information section of this Privacy Policy;
        <br />
        <br />
        Service Providers: We use other companies, agents or contractors
        ("Service Providers") to perform services on our behalf and/or to assist
        us with the provision of services to you. For example, we engage Service
        Providers to provide marketing, advertising, communications,
        infrastructure and IT services, to personalize and optimize our service,
        to process credit card transactions or other payment methods, to provide
        customer service, to collect debts, to analyze and enhance data
        (including data about users' interactions with our service), and to
        process and administer consumer surveys. In the course of providing such
        services, these Service Providers may have access to your personal or
        other information. We do not authorize them to use or disclose your
        personal information except in connection with providing their services;
        <br />
        <br />
        Promotional offers: We may offer joint promotions or programs that, in
        order for your participation, will require us to share your information
        with third parties. In fulfilling these types of promotions, we may
        share your name and other information in connection with fulfilling the
        incentive. Please note that these third parties are responsible for
        their own privacy practices;
        <br />
        <br />
        Protection of Pocket Films and others: Pocket Films and its Service
        Providers may disclose and otherwise use your personal and other
        information where we or they reasonably believe such disclosure is
        needed to (a) satisfy any applicable law, regulation, legal process, or
        governmental request, (b) enforce applicable terms of use, including
        investigation of potential violations thereof, (c) detect, prevent, or
        otherwise address illegal or suspected illegal activities (including
        payment fraud), security or technical issues, or (d) protect against
        harm to the rights, property or safety of Pocket Films, its users or the
        public, as required or permitted by law;
        <br />
        <br />
        Business transfers: In connection with any reorganization,
        restructuring, merger or sale, or other transfer of assets, we will
        transfer information, including personal information, provided that the
        receiving party agrees to respect your personal information in a manner
        that is consistent with our Privacy Statement.
        <br />
        <br />
        Whenever in the course of sharing information we transfer personal
        information to other countries and other regions with comprehensive data
        protection laws, we will ensure that the information is transferred in
        accordance with this Privacy Policy and as permitted by the applicable
        laws on data protection.
        <br />
        <br />
        You may also choose to disclose your information in the following ways:
        <br />
        <br />
        While using the Pocket Films service you will have opportunities to post
        reviews or other information publicly, and third parties could use the
        information you disclose;
        <br />
        <br />
        Certain portions of our service may contain a tool which gives you the
        option to share information by email, text message and social or other
        sharing applications, using the clients and applications on your
        devices;
        <br />
        <br />
        Social plugins (including those offered by Facebook, Twitter, Pinterest,
        and Google) allow you to share information on those platforms.
        <br />
        <br />
        Social plugins and social applications are operated by the social
        network themselves, and are subject to their terms of use and privacy
        policies.
        <br />
        <br />
        <b>Access to Your Account and Profiles</b>
        <br />
        In order to provide you with ease of access to your account and to help
        administer the Pocket Films service, we use technology that enables us
        to recognize you as the account holder and provide you with direct
        access to your account without requiring you to retype any password or
        other user identification when you revisit the Pocket Films service.
        <br />
        <br />
        If you share or otherwise allow others to have access to your account,
        they will be able to see your information (including in some cases
        personal information) such as your watch history, ratings, reviews and
        account information (including your email address or other information
        in Your Account). This remains true even if you use our profiles
        feature.
        <br />
        <br />
        Profiles allow different members of your household to have a distinct,
        personalized Pocket Films experience, built around the content they
        enjoy, such as personalized recommendations and separate watch
        histories. Please note that profiles, if any, are available to everyone
        who uses your Pocket Films account, so that anyone with access to your
        Pocket Films account can navigate to and use, edit or delete any
        profile. You should explain this to others with access to your account,
        and if you do not wish them to use or update your profile, be sure to
        let them know.
        <br />
        <br />
        <b>Your Choices</b>
        <br />
        If you no longer want to receive certain communications from us via
        email or text message click the "unsubscribe" link in the email or reply
        STOP (or as otherwise instructed) to the text message. Please note that
        you cannot unsubscribe from certain email and text correspondence from
        us, such as messages relating to your account transactions.
        <br />
        <br />
        To exercise choices regarding cookies set through our website, as well
        as other types of online tracking and Internet advertising, see the
        Cookies and Internet Advertising section (below).
        <br />
        <br />
        <b>Your Information and Rights </b>
        <br />
        <br />
        <br />
        You can request access to your personal information, or correct or
        update out-of-date or inaccurate personal information we hold about you.
        <br />
        <br />
        You can most easily do this by visiting the "Your Account" portion of
        our website, where you have the ability to access and update a broad
        range of information about your account, including your contact
        information, your Pocket Films payment information, if any, and various
        related information about your account (such as the content you have
        viewed and rated, and your reviews). You must be signed in to access
        "Your Account." You may also request that we delete personal information
        that we hold about you.
        <br />
        <br />
        To make requests, or if you have any other question regarding our
        privacy practices, please contact us at info@pocketfilms.in
        <br />
        <br />
        We may reject requests that are unreasonable or not required by law,
        including those that would be extremely impractical, could require
        disproportionate technical effort, or could expose us to operational
        risks such as free trial fraud. We may retain information as required or
        permitted by applicable laws and regulations, including to honor your
        choices, for our billing or records purposes and to fulfill the purposes
        described in this Privacy Policy.
        <br />
        <br />
        <b>Security</b> <br />
        We use reasonable administrative, logical, physical and managerial
        measures to safeguard your personal information against loss, theft and
        unauthorized access, use and modification. Unfortunately, no measures
        can be guaranteed to provide 100% security. Accordingly, we cannot
        guarantee the security of your information.
        <br />
        <br />
        Other Websites, Platforms and Applications
        <br />
        <br />
        Pocket Films service may be provided through and/or utilize features
        (such as voice controls) operated by third party platforms, or contain
        links to sites operated by third parties whose policies regarding the
        handling of information may differ from ours. For example, you may be
        able to access the Pocket Films service through platforms such as gaming
        systems, smart TVs, mobile devices, set top boxes and a number of other
        Internet connected devices. These websites and platforms have separate
        and independent privacy or data policies, privacy statements, notices
        and terms of use, which we recommend you read carefully. In addition,
        you may encounter third party applications that interact with the Pocket
        Films service.
        <br />
        <br />
        Children
        <br />
        <br />
        You must be 18 years of age or older to use, view, register or subscribe
        to the Pocket Films service. In certain jurisdictions, the age of
        majority may be older than 18, in which case, you must satisfy that age
        in order to become a member. While individuals under the age of 18 may
        utilize the service, they may do so only with the involvement,
        supervision, and approval of a parent or legal guardian.
        <br />
        <br />
        <b>Changes to This Privacy Policy</b>
        <br />
        Pocket Films reserves the right, at its sole discretion, to change,
        modify, add or remove any portion of the Privacy Policy in whole or in
        part, at any time. Changes to the Privacy Policy will be effective when
        posted. You agree to review the Privacy Policy periodically to become
        aware of any changes. The use of the Site after any changes to the
        Privacy Policy are posted will be considered acceptance of those changes
        by you and will constitute your agreement to be bound thereby. If you
        object to any such changes, your sole recourse will be to stop using the
        Site.
        <br />
        <br />
        <b>Cookies and Internet Advertising </b>
        <br />
        We and our Service Providers use cookies and other technologies (such as
        web beacons) for various reasons. For example, we use cookies and other
        technologies to make it easy to access our services by remembering you
        when you return, to provide and analyze our services, to learn more
        about our users and their likely interests, and to deliver and tailor
        marketing or advertising. We want you to be informed about our use of
        these technologies, so this notice explains the types of technologies we
        use, what they do and your choices regarding their use.
        <br />
        <br />
        What are cookies?
        <br />
        <br />
        Cookies are small data files that are commonly stored on your device
        when you browse and use websites and online services. They are widely
        used to make websites work, or to work more efficiently, as well as to
        provide reporting information and assist with service or advertising
        personalization.
        <br />
        <br />
        Cookies are not the only types of technology that enable this
        functionality; we also use other, similar types of technologies. See
        below for more information and examples.
        <br />
        <br />
        Why does Pocket Films use cookies?
        <br />
        <br />
        We and our Service Providers may use the following types of cookies:
        <br />
        <br />
        Essential cookies: These cookies are strictly necessary to provide our
        website or online service. For example, we or our Service Providers may
        use these cookies to authenticate and identify our members when they use
        our websites and applications so we can provide our service to them.
        They also help us to enforce our Terms of Use, prevent fraud and
        maintain the security of our service. <br />
        Performance and functionality cookies: These cookies are not essential,
        but help us to personalize and enhance your online experience with
        Pocket Films. For example, they help us to remember your preferences and
        prevent you from needing to re-enter information you previously provided
        (for example, during member sign up). We also use these cookies to
        collect information (such as popular pages, conversion rates, viewing
        patterns, click-through and other information) about our visitors' use
        of the Pocket Films service so that we can enhance and personalize our
        website and service and conduct market research. Deletion of these types
        of cookies will result in limited functionality of our service.
        <br />
        Advertising cookies: These cookies use information about your visit to
        this and other websites, such as the pages you visit, your use of our
        service or your response to ads and emails, to deliver ads that are more
        relevant to you. These types of ads are called "Interest-Based
        Advertising." Many of the advertising cookies associated with our
        service belong to our Service Providers.
        <br />
        <br />
        How can I exercise choice regarding cookies and other types of online
        tracking?
        <br />
        <br />
        You will have to accept cookies to access Pocket Films’ services. At
        this time, we do not respond to Web browser "do not track" signals.
        <br />
        <br />
        How Does Pocket Films use Web Beacons and other Technologies?
        <br />
        <br />
        Web beacons (also known as clear gifs or pixel tags) often work in
        conjunction with cookies. We and our Service Providers may use them for
        similar purposes as cookies, such as to understand and enhance the use
        of our service, improve site performance, monitor visitor traffic and
        actions on our site, and understand interactions with our marketing
        (including email and online ads on third party sites). Because web
        beacons often work in conjunction with cookies, in many cases, declining
        cookies will impair the effectiveness of web beacons.
        <br />
        <br />
        We use other technologies that are similar to cookies, such as browser
        storage and plugins (e.g., HTML5, IndexedDB, and WebSQL). Like cookies,
        some of these technologies may store small amounts of data on your
        device. We may use these and various other technologies for similar
        purposes as cookies, such as to enforce our terms, prevent fraud, and
        analyze the use of our service. There are a number of ways to exercise
        choice regarding these technologies. For example, many popular browsers
        provide the ability to clear browser storage, commonly in the settings
        or preferences area; see your browser’s help function or support area to
        learn more.
      </Paragraph>
    </Main>
  );
};

const Main = styled.div`
  width: 100vw;
  min-height: 100vh;
  padding: 4rem;
`;

const Title = styled.h2`
  font-size: 3.8rem;
  font-weight: 600;
  color: #fff;
  text-align: center;
`;

const Paragraph = styled.div`
  font-size: 1.4rem;
  font-weight: 300;
  color: #fff;
  text-align: justify;
`;
