import { styled } from "styled-components";
import { MdChevronLeft } from "react-icons/md";
import { useNavigate } from "react-router-dom";

export const BackButton = () => {
  const navigate = useNavigate();

  return (
    <BackButtonContainer
      onClick={() => {
        if (window.history.length > 1) {
          navigate(-1);
        } else {
          navigate("/");
        }
      }}
    >
      <MdChevronLeft size={25} color="#040C15" />
    </BackButtonContainer>
  );
};

const BackButtonContainer = styled.div`
  position: fixed;
  top: 2rem;
  left: 2rem;
  width: 5rem;
  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  z-index: 3;
`;
