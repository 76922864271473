import { createBrowserRouter } from "react-router-dom";
import { LandingPage } from "./pages/landing/landing.page";
import { FAQPage } from "./pages/faq/faq.page";
import { TNCPage } from "./pages/tnc/tnc.page";
import { PrivacyPolicyPage } from "./pages/privacy-policy/privacy-policy.page";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/faq",
    element: <FAQPage />,
  },
  {
    path: "/faq/:section",
    element: <FAQPage />,
  },
  {
    path: "/terms-and-conditions",
    element: <TNCPage />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicyPage />,
  },
]);
