import { styled } from "styled-components";

export const HeroSection = () => {
  return (
    <Container>
      <Title>Revolutionize the way you view short films.</Title>
      <Button
        onClick={() =>
          window.open(
            process.env.REACT_APP_DISTRIBUTE_URL ??
              "https://distribute.pocketfilms.in"
          )
        }
      >
        Get Started
      </Button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 0.7)
    ),
    url("/landing-bg.png");
`;

const Title = styled.h1`
  font-size: 5.7rem;
  font-weight: 600;
  width: 60%;
  color: #fff;
  text-align: center;

  @media only screen and (max-width: 34.375em) {
    width: 80%;
  }
  @media only screen and (max-width: 23.4375em) {
    font-size: 5rem;
  }
`;

const Paragraph = styled.p`
  font-size: 1.8rem;
  font-weight: 350;
  color: #fff;
  width: 60%;
  text-align: center;
  margin-top: 1rem;
`;

const Button = styled.div`
  padding: 1.5rem 3rem;
  border-radius: 10rem;
  background-color: #fff;
  font-size: 1.6rem;
  font-weight: 600;
  margin-top: 2rem;
  cursor: pointer;
`;
